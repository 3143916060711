import React, { useState, useEffect } from 'react';
import './CollageComponent.css';

const CollageComponent2 = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Function to import all images from the collageimages directory
        const importAll = (r) => {
            return r.keys().map(r);
        };

        try {
            // Import all images that match these extensions
            const importedImages = importAll(
                require.context('./collageimages2', false, /\.(png|jpe?g|svg)$/)
            );
            setImages(importedImages);
        } catch (error) {
            console.error("Error importing images:", error);
            setImages([]);
        }
    }, []); // Empty dependency array means this runs once on mount

    return (
        <>
            <hr className="" />
            <div className="">
                <div className="container" id="">
                    {images.map((image, index) => (
                        <img 
                            key={index}
                            src={image}
                            alt={`Item ${index + 1}`}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default CollageComponent2;